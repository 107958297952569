import React from 'react';
import SlidesTitle from './SlidesTitle';
import SwiperCore, {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import KirdContainer from "components/layouts/KirdContainer";
import style from './ChannelSlides.module.css';
import ChannelCard from "components/card/ChannelCard";
import {Channel} from "components/types/Channel";

SwiperCore.use([Navigation]);

interface Props {
    id?: string;
    title: string;
    list: Array<Channel>;
}

export default function ChannelSlides(props: Props) {
    const {id, title, list} = props;

    return (
        <KirdContainer className={style.channelSliderWrap}>
            <SlidesTitle title={title} id={id}/>
            <div className={style.processContGroup}>
                <Swiper speed={500}
                        slidesPerView='auto'
                        spaceBetween={16}
                        navigation={{prevEl: `#${id} .btnPrev`, nextEl: `#${id} .btnNext`}}
                        breakpoints={{
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 35,
                            },
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 16,
                            }
                        }}
                >
                    {list?.map((channel: Channel) => (
                        <SwiperSlide key={channel.id}>
                            <ChannelCard channel={channel}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </KirdContainer>
    );
};

