import {useTranslation} from 'next-i18next';
import styles from './SlidesTitle.module.css';
import {useEffect } from "react";


interface Props {
    id?: string,
    title: string,
}

const SlidesTitle = (props: Props) => {
    const {t} = useTranslation();

    useEffect(()=>{
      // 해쉬값을 추출하여 특정 요소로 이동
      const hashValue = window.location.hash.substring(1);
      if (hashValue == 'subjectCurationListByCompetencySlide') {
        const element = document.getElementById(hashValue);
        if (element) {
          requestAnimationFrame(() => {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
            // block값으로 특정 요소의 중앙, 상단 등으로 이동위치 지정
            // 상단으로 지정하면 상단 메뉴에 가리기 때문에 중앙으로 지정
          });
        }
      }
    }, [])

    return (
        <div className={styles.contentsTitGroup} id={props.id}>
            <div className={styles.title}><strong>{props.title}</strong></div>
            <div className={styles.pagenation}>
                <button className={['btnPrev', styles.btnPrev].join(' ')}>{t('이전')}</button>
                <button className={['btnNext', styles.btnNext].join(' ')}>{t('다음')}</button>
            </div>
        </div>
    );
};

export default SlidesTitle;
